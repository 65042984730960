import React, { useEffect, useRef } from 'react';
import Link from 'next/link';
import { IconChevronDown } from '@/snippets/index';
import { useCountry } from '@backpackjs/storefront';

function NavDesktop({ menu }) {
  const childItem = useRef([]);

  let menuLeaveTimer;
  const lis = childItem.current;

  const openMegaMenu = () => {
    clearTimeout(menuLeaveTimer);
    const scrollTop = window.scrollY;
    if (!document.body.style.getPropertyValue('--topPosition')) {
      document.body.style.setProperty('--topPosition', `-${scrollTop}px`);
      document.body.classList.add('bg-overlay', 'drawer-open');
    }

    const megaMenus = document.querySelectorAll('.mega-menu');

    if (megaMenus.length > 0) {
      const header = document.querySelector('.site-header');
      const headerHeight = header.offsetHeight + header.getBoundingClientRect().top;
      megaMenus.forEach((megaMenu) => {
        megaMenu.style.maxHeight = `calc(100vh - ${headerHeight}px)`;
      });
    }
  };

  const closeMegaMenu = () => {
    menuLeaveTimer = setTimeout(() => {
      document.body.classList.remove('bg-overlay', 'drawer-open');
      const scrollTop = document.body.style.getPropertyValue('--topPosition');
      document.body.style.removeProperty('--topPosition');
      window.scrollTo({
        top: scrollTop.replace('px', '') * -1,
      });
    }, 400);
  };

  useEffect(() => {
    if (lis) {
      lis.forEach((el) => {
        if (el?.classList?.contains('has-child')) {
          el?.addEventListener('mouseover', openMegaMenu);
          el?.addEventListener('mouseout', closeMegaMenu);
        }
      });
    }
    return () => {
      if (lis) {
        lis.forEach((el) => {
          if (el?.classList?.contains('has-child')) {
            el?.removeEventListener('mouseover', openMegaMenu);
            el?.removeEventListener('mouseout', closeMegaMenu);
          }
        });
      }
    };
  }, []);

  return (
    <div className="hidden flex-1 lg:flex">
      <nav id="main-navbar" className="hidden lg:block">
        {menu &&
          <ul className="m-0 flex h-full list-none items-center px-8">
            {menu.map((menuLink, menuLinkIndex) => {
              const columns = menuLink?.column.filter((column) => column.enable_column) || [];
              const num_col = columns.reduce((accumulator, currentValue) => accumulator += currentValue?.featured_column == true ? 2 : 1, 0);
              const is_megamenu =
                num_col > 1 || (num_col === 1 && columns[0]?.cta?.image);
              return menuLink?.link?.text ? (
                <li
                  key={`menu-item-${menuLinkIndex}`}
                  className={`level-1 block mt-0 ml-11 first:ml-0${
                    num_col ? ' has-child' : ''
                  }`}
                  ref={(ref) => {
                    childItem.current[menuLinkIndex] = ref;
                  }}
                >
                  <Link href={menuLink?.link?.url ? menuLink.link.url : '/'}>
                    <a className="relative block whitespace-nowrap font-gothamBold lg:text-[14px] xl:text-base no-underline">
                      {menuLink?.link?.text}
                      {num_col ? (
                        <span className="arrow">
                          <IconChevronDown />
                        </span>
                      ) : (
                        ''
                      )}
                    </a>
                  </Link>
                  {num_col ? (
                    <div
                      className={`${
                        is_megamenu
                          ? 'mega-menu-container left-0 w-full'
                          : 'dropdown-menu-container w-78 -ml-4'
                      } absolute top-full`}
                    >
                      <div
                        className={`${
                          is_megamenu
                            ? 'mega-menu flex flex-wrap'
                            : 'dropdown-menu px-14 py-9'
                        } relative z-0 border-b border-grey-7 bg-white`}
                      >
                        {columns.map((col, colIndex) => {
                          return col?.enable_column === true ? (
                            is_megamenu ? (
                              <ChildMegaMenu
                                key={`menu-column-${colIndex}`}
                                menuItem={col}
                                ColNumber={num_col}
                              />
                            ) : (
                              <ChildDopdownMenu
                                key={`menu-column-${colIndex}`}
                                menuItem={col}
                              />
                            )
                          ) : (
                            false
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    false
                  )}
                </li>
              ) : (
                false
              );
            })}
          </ul>
        }
      </nav>
    </div>
  );
}

function ChildDopdownMenu({ menuItem }) {
  if (!menuItem) return null;
  return menuItem?.menu ? (
    <ul className="list-none p-0">
      {menuItem.menu &&
        menuItem.menu.map((childmenu, childmenuIndex) => {
          const child_menu = childmenu?.child_menu || [];
          return (
            <li
              key={`menu-item-${childmenuIndex}`}
              className={`level-2 my-2.5${
                childmenu?.bold_underline === true ? ' mt-3' : ''
              }`}
            >
              <Link href={childmenu?.link?.url ? childmenu?.link?.url : '/'}>
                <a
                  className={`relative block inline-block text-sm no-underline underline-offset-4 leading-normal${
                    childmenu?.link_icon ? ' flex items-center' : ''
                  }${
                    childmenu?.bold_underline === true
                      ? ' border-b-2 border-black !pb-1 font-gothamBold hover:border-transparent hover:text-black'
                      : ' hover:underline'
                  }${
                    child_menu.length && childmenu?.bold_underline === false
                      ? ' font-gothamBold'
                      : ''
                  }`}
                >
                  {childmenu?.link_icon && (
                    <img
                      className="mr-3 h-6 w-6 object-cover"
                      src={childmenu?.link_icon?.src}
                      loading="lazy"
                      alt={childmenu?.link?.text}
                    />
                  )}
                  <span>
                    <span>{childmenu.link?.text}</span>
                    {childmenu?.promo_text ? (
                      <span className="promo-text ml-2 uppercase text-primary-2">
                        {childmenu?.promo_text}
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                </a>
              </Link>
            </li>
          );
        })}
    </ul>
  ) : (
    !1
  );
}

function ChildMegaMenu({ menuItem, ColNumber }) {
  const { country } = useCountry();
  const isUs = country?.isoCode === 'US';
  // Note: added logic to hide virtual bundles from non-US countries (see line 326)

  if (!menuItem || (!menuItem?.cta && !menuItem?.menu)) return null;
  const has_cta = menuItem?.cta && menuItem?.cta?.image;
  const featured_column = menuItem?.featured_column || false;

  const temp_class = 'w-1/2 w-1/3 w-1/4 w-1/5 xl:w-1/2 xl:w-1/3 xl:w-1/4 xl:w-1/5 xl:w-2/3 xl:w-2/4 xl:w-2/5';
  let w_class = `w-1/${Math.max(ColNumber, 3)}`;
  if (ColNumber === 1) {
    w_class = has_cta ? 'w-full' : `w-1/${Math.max(ColNumber, 3)}`;
  } else {
    w_class = (has_cta == true || featured_column == true)
      ? `w-1/${Math.max(ColNumber-1, 3)} xl:w-2/${Math.max(ColNumber, 3)}`
      : `w-1/${Math.max(ColNumber-1, 3)} xl:w-1/${Math.max(ColNumber, 3)}`;
  }

  return (
    <div
      className={`${w_class} ${
        has_cta ? '' : ' border-l border-grey-7 first:border-l-0'
      }`}
    >
      {has_cta ? (
        <div className="menu-item-cta">
          {menuItem?.cta.url ? (
            <Link href={menuItem?.cta.url}>
              <a className="no-underline hover:underline">
                <img
                  src={menuItem?.cta?.image?.src}
                  loading="lazy"
                  className="h-full w-full object-cover"
                  alt={menuItem?.cta?.title}
                />
                {menuItem?.cta?.title && (
                  <span className="my-3 mx-4 block font-gothamBold">
                    {menuItem?.cta?.title}
                  </span>
                )}
              </a>
            </Link>
          ) : (
            <>
              <img
                src={menuItem?.cta?.image?.src}
                loading="lazy"
                className="h-full w-full object-cover"
                alt={menuItem?.cta?.title}
              />
              {menuItem?.cta?.title && (
                <span className="my-3 mx-4 block font-gothamBold">
                  {menuItem?.cta?.title}
                </span>
              )}
            </>
          )}
        </div>
      ) : null}
      {menuItem?.menu ? (
        <ul className={`pt-9 pb-9 ${menuItem.featured_column ? 'px-3 xl:px-14 flex flex-wrap' : 'px-14'}`}>
          {menuItem?.menu &&
            menuItem?.menu.map((childmenu, childmenuIndex) => {
              const child_menu = childmenu?.child_menu || [];
              return childmenu?.link?.text ? (
                <React.Fragment key={childmenuIndex}>
                  {child_menu.length ? (
                    <li
                      key={`menu-item-${childmenuIndex}`}
                      className={`level-2 mt-4 mb-3 ${menuItem.featured_column ? 'w-full px-2' : ''}`}
                    >
                      <p className="m-0 font-gothamBold">
                        {childmenu.link.text}
                      </p>
                    </li>
                  ) : (
                    <li
                      key={`menu-item-${childmenuIndex}`}
                      className={`level-2 my-2.5${
                        childmenu?.bold_underline === true ? ' mt-3' : ''
                      }`}
                    >
                      <Link
                        href={childmenu?.link?.url ? childmenu.link.url : '/'}
                      >
                        <a
                          className={`relative inline-block text-sm no-underline underline-offset-4 leading-normal${
                            childmenu?.link_icon ? ' flex items-center' : ''
                          }${
                            childmenu?.bold_underline === true
                              ? ' border-b-2 border-black !pb-1 font-gothamBold hover:border-transparent hover:text-black'
                              : ' hover:underline'
                          }`}
                          style={{ color: childmenu?.link_color }}
                        >
                          {childmenu.link_icon && (
                            <img
                              className="mr-3 object-cover"
                              src={childmenu?.link_icon?.src}
                              loading="lazy"
                              alt={childmenu?.link?.text}
                            />
                          )}
                          <span>
                            <span>{childmenu?.link?.text}</span>
                            {childmenu?.promo_text ? (
                              <span className="promo-text ml-2 uppercase text-primary-2">
                                {childmenu?.promo_text}
                              </span>
                            ) : (
                              ''
                            )}
                          </span>
                        </a>
                      </Link>
                    </li>
                  )}
                  {child_menu.map((item, itemIndex) => {
                    return (
                      <li
                        key={`menu-item-${itemIndex}`}
                        className={`level-2 my-2.5${
                          item?.bold_underline === true ? ' mt-3' : ''
                        }
                        ${(!isUs && item?.link?.text.toLowerCase() === 'bundles') ? 'hidden' : ''}
                        ${menuItem.featured_column ? 'w-1/2 xl:w-1/4 px-2 xl:w-1/4 text-center' : ''}
                        `}
                      >
                        <Link href={item?.link?.url ? item.link.url : '/'}>
                          <a
                            className={`relative inline-block text-sm no-underline underline-offset-4 leading-normal${
                              item?.link_icon ? ' flex items-center' : ''
                            }
                            ${menuItem.featured_column ? 'flex-col leading-[1.5] text-xs' : ''}
                            ${
                              item?.bold_underline === true
                                ? ' border-b-2 border-black !pb-1 font-gothamBold hover:border-transparent hover:text-black'
                                : ' hover:underline'
                            }${item?.link_hover_image ? ' has-hover-cta' : ''}
                            `}
                            style={{ color: item?.link_color }}
                          >
                            {item.link_icon && (
                              <img
                                className={`object-cover ${
                                  menuItem.featured_column
                                    ? 'block w-full mb-2'
                                    : 'mr-3 h-6 w-6'
                                }`}
                                src={item?.link_icon?.src}
                                loading="lazy"
                                alt={item?.link?.text}
                              />
                            )}
                            <span>
                              <span className={`${menuItem.featured_column ? 'font-gothamBold' : ''}`}>{item?.link?.text}</span>
                              {!!item?.promo_text && (
                                <span className="promo-text ml-2 uppercase text-primary-2">
                                  {item?.promo_text}
                                </span>
                              )}
                            </span>
                          </a>
                        </Link>
                        {item?.link_hover_image ? (
                          <div className="hover-cta">
                            <div className="responsive-image !h-full">
                              <img
                                src={item?.link_hover_image?.src}
                                className="hidden w-full lg:block"
                                alt={item?.link?.text}
                              />
                            </div>
                          </div>
                        ) : null}
                      </li>
                    );
                  })}
                </React.Fragment>
              ) : null;
            })}
        </ul>
      ) : null}
    </div>
  );
}

NavDesktop.displayName = 'NavDesktop';
ChildMegaMenu.displayName = 'ChildMegaMenu';

export default NavDesktop;
