import React, { useEffect } from 'react';
import { useSettings, useCountry, useCart } from '@backpackjs/storefront';
import { getApplicableOverride } from '@/utils/Helper';

function DiscountBanner() {
  const settings = useSettings();
  const { country } = useCountry();
  const countryCode = country?.isoCode || 'US';
  const cart = useCart();
  const overrides = settings?.discountBanner?.overrides;
  let rotateTimer = null;

  const stopTimer = () => {
    if (rotateTimer) clearInterval(rotateTimer);
  };
  const rotateMessage = (step) => {
    const msgItems = document.querySelectorAll('.discount-banner-msg-item');
    const prevIdx = Array.from(msgItems).findIndex((e) =>
      e.classList.contains('active')
    );
    const nextIdx =
      (msgItems.length + ((prevIdx + step) % msgItems.length)) %
      msgItems.length;
    const prevItem = msgItems.item(prevIdx);
    const nextItem = msgItems.item(nextIdx);
    prevItem?.classList?.remove('active');
    setTimeout(() => {
      nextItem?.classList?.add('active');
      prevItem?.classList?.add('hidden');
      nextItem?.classList?.remove('hidden');
    }, 150);
  };
  const startTimer = () => {
    stopTimer();
    rotateTimer = setInterval(() => {
      rotateMessage(1);
    }, 5000);
  };

  const rotatePrev = () => {
    stopTimer();
    rotateMessage(-1);
    setTimeout(startTimer, 5000);
  };
  const rotateNext = () => {
    stopTimer();
    rotateMessage(1);
    setTimeout(startTimer, 5000);
  };
  const applicableOverride = getApplicableOverride(cart, overrides);

  useEffect(() => {
    const autoRotate =
      countryCode === 'US'
        ? settings?.discountBanner?.toggleAutoRotate
        : settings?.discountBanner?.toggleAutoRotateInt;

    if (countryCode && autoRotate && !applicableOverride?.length) {
      startTimer();
    }
    return () => stopTimer();
  }, [country, settings, applicableOverride]);

  let bannerMessages =
    countryCode === 'US'
      ? settings?.discountBanner?.messages
      : settings?.discountBanner?.messagesInt;

  if (applicableOverride?.length) {
    bannerMessages = applicableOverride;
  }

  const bgColor =
    countryCode === 'US'
      ? settings?.discountBanner?.bgColor
      : settings?.discountBanner?.bgColorInt;

  const messages = bannerMessages?.map((msg, idx) => {
    const msgDesktop = msg.messageDesktop || msg.messageMobile;
    const msgMobile = msg.messageMobile || msg.messageDesktop;
    return { messageDesktop: msgDesktop, messageMobile: msgMobile };
  });

  let textClass = 'text-white';
  const bgClass = `bg-${bgColor}`;
  if (bgColor === 'quinary-2') {
    textClass = 'text-grey-1';
  }

  return (
    (messages &&
    messages.length > 0) && (
      <div
        className={`${bgClass} ${textClass} relative z-[2000] flex items-center justify-center px-3 py-[6px] md:py-[9px]`}
      >
        {messages.length > 1 && (
          <button type="button" className="mr-[6px] p-1" onClick={rotatePrev}>
            <svg
              width="5"
              height="8"
              viewBox="0 0 5 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 1L1 4L4 7"
                stroke="currentColor"
                strokeLinecap="square"
              />
            </svg>
          </button>
        )}
        <div className="discount-banner-msg-list w-full md:w-auto">
          {messages.map((message, messageIdx) => (
            <div
              className={`discount-banner-msg-item m-0 p-0 ${
                messageIdx === 0 ? 'active' : 'hidden'
              }`}
              key={`discount-banner-msg-${messageIdx}`}
            >
              {message?.messageDesktop && (
                <span
                  className="hidden text-[14px] leading-[22px] md:block"
                  dangerouslySetInnerHTML={{ __html: message.messageDesktop }}
                />
              )}
              {message?.messageMobile && (
                <span
                  className="block text-center text-[12px] leading-normal md:hidden"
                  dangerouslySetInnerHTML={{ __html: message.messageMobile }}
                />
              )}
            </div>
          ))}
        </div>
        {messages.length > 1 && (
          <button type="button" className="ml-[6px] p-1" onClick={rotateNext}>
            <svg
              width="5"
              height="8"
              viewBox="0 0 5 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 7L4 4L1 1"
                stroke="currentColor"
                strokeLinecap="square"
              />
            </svg>
          </button>
        )}
      </div>
    )
  );
}

export default DiscountBanner;
