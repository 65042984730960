import { Fragment } from 'react';
import Link from 'next/link';

function Section({ cms }) {
  return (
    <div data-comp="productsizes">
      <div className="product-info-container--sizes mb-6">
        <p className="text-small mb-1 font-gothamBold text-black">Size</p>
        <div className="mx-auto inline-grid w-full grid-cols-3 gap-px text-grey-3">
          {cms?.productsizes?.map((productsizes, index) => {
            return (
              <Fragment key={index}>
                <Link href={`/products/${productsizes?.productName?.handle}`}>
                  <a
                    className={`product-size bg-grey-9 no-underline outline outline-1 outline-grey-4 duration-200 hover:z-[1] hover:bg-grey-9 text-black hover:outline-black font-gothamBold${
                      productsizes?.enable_active_pdp === true
                        ? ' product-size-active z-[1] bg-white text-black outline-black hover:bg-white'
                        : ''
                    }${
                      productsizes?.unavailable_pdp === true
                        ? ' product-size-disabled'
                        : ''
                    }`}
                  >
                    <div className="flex flex-col p-2 text-center">
                      <span className="text-small font-gothamBold leading-[19px]">
                        {productsizes?.label}
                      </span>
                      <span className="text-small  font-gothamBold leading-[19px]">
                        {productsizes?.size}
                      </span>
                    </div>
                  </a>
                </Link>
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}

Section.displayName = 'ProductSizes';

Section.Schema = {
  category: 'Product',
  label: Section.displayName,
  key: 'productsizes',
  fields: [
    {
      name: 'productsizes',
      label: 'Product Size',
      component: 'group-list',
      fields: [
        {
          component: 'productSearch',
          name: 'productName',
          label: 'Select Product',
        },
        {
          name: 'label',
          label: 'Size Title',
          component: 'text',
        },
        {
          name: 'size',
          label: 'Size Amount',
          component: 'text',
        },
        {
          component: 'toggle',
          name: 'enable_active_pdp',
          label: 'Enable',
          description: 'Is this size on the current PDP?',
          defaultValue: false,
        },
        {
          component: 'toggle',
          name: 'unavailable_pdp',
          label: 'Enable',
          description: 'Is this size out of stock?',
          defaultValue: false,
        },
      ],
    },
  ],
};

export const ProductSizes = Section;
