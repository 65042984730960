import { useQuizContext } from "./QuizContext";
import { useEffect, useRef, useState } from "react";
import QuizProduct from "./QuizProduct";
import { Markdown } from "@/snippets/globals/layout/Markdown";
import Styles from './Quiz.module.css';
import { Info } from "@/snippets/icons/Info";
import { Close } from "@/snippets/icons/Close";
import { useWindowDimensions } from "@/utils/Helper";
import { useCartAddItems, useProductsFromHandles } from "@backpackjs/storefront";
import Link from "next/link";
import { Image } from "@/snippets/components/Image";
import { useScrollLock } from "@hooks/ScrollLock";
import { useKlaviyoApi } from "@hooks/useKlaviyoApi";


export function QuizResult () {
  const context = useQuizContext();
  const {scrollLock, scrollUnlock} = useScrollLock();
  const emailField = useRef(null);
  const [summaryOpen, setSummaryOpen] = useState(false);
  const { vpWidth } = useWindowDimensions();
  const isMobile = vpWidth < 767;
  const {cartAddItems} = useCartAddItems();
  const [saveResultsModalOpen, setSaveResultsModalOpen] = useState(false);
  const { sendEvent } = useKlaviyoApi();
  const [saveResultsMessage, setSaveResultsMessage] = useState('');
  const [saveResultError, setSaveResultError] = useState(false);

  const {
    quizSettings,
    quizData,
    updateQuizData,
    getResultsProducts,
    parseQuizResultUrl,
    buildSummaryResults,
  } = context;

  const toggleResultSummary = () => {
    setSummaryOpen(!summaryOpen);
  }

  const addAllProductsToCart = async () => {
    const itemsToAdd = parsedProducts.map((product) => {
      return {
        merchandiseId: product.variants[0].id,
        quantity: 1,
        attributes: [
          {
            key: '_sc',
            value: (typeof window.ShipScoutLineItemProperty !== 'undefined' ? window.ShipScoutLineItemProperty : 'null'),
          }
        ]
      }
    })

    cartAddItems(itemsToAdd).then(() => {
      window.dispatchEvent(new CustomEvent('openSideCart'));
    });
  }


  const resultProducts = getResultsProducts();
  const {products: parsedProducts} = useProductsFromHandles({version: 'full', handles: resultProducts.map((productObject) => productObject?.product?.handle)});
  const dailyProducts = resultProducts.filter((productObject) => productObject?.routine == 'daily');
  const specializedProducts = resultProducts.filter((productObject) => productObject?.routine == 'specialized');
  const summaryResults = buildSummaryResults();
  const summaryPopup = (
    <div className="quiz-result__summary-content absolute left-1/2 -mt-[1.5em] w-[348px] max-w-full -translate-x-1/2 translate-y-[2em] border border-grey-9 bg-white py-4 px-5 text-left lg:top-0 lg:mt-0 md:max-w-none">
      <span className="absolute top-[-1px] left-1/2 hidden h-2.5 w-2.5 -translate-x-1/2 -translate-y-1/2 rotate-45 border-t border-l border-grey-9 bg-white md:block"></span>
      <span className="block pr-5 font-gothamBold text-xs leading-normal">
        Your quiz selections:
      </span>
      <button
        type="button"
        onClick={toggleResultSummary}
        className="absolute top-5 right-4"
      >
        <div className="sr-only">Close summary</div>
        <Close className="h-2.5 w-2.5" />
      </button>
      <ol className="my-2 list-inside pl-0 text-xs">{
        summaryResults.map((result, index) => {
          return (
            <li key={`result-${index}`} className="leading-relaxed m-0">
              <span>{result.label}:</span>
              <span className="font-gothamBold ml-1">{result.answer}</span>
            </li>
          )
        })
      }</ol>
    </div>
  );

  const toggleSaveResultsModal = () => {
    setSaveResultsModalOpen((isOpen) => {
      if(isOpen) {
        scrollUnlock();
        return false;
      } else {
        setSaveResultsMessage('');
        scrollLock();
        return true;
      }
    })
  }

  const sendResultsToEmail = (e) => {
    e.preventDefault();
    const email = emailField.current.value;
    const customerProperties = buildSummaryResults();
    customerProperties = customerProperties.reduce((previousObject, currentObject) => {
      return Object.assign(previousObject, {[currentObject.label.toLowerCase().replaceAll(' ', '_')]: currentObject.answer})
    }, {});


    if(email == '') {
      return;
    }

    const parsedResultProducts = resultProducts
      .filter((result) => typeof result !== 'undefined')
      .map((resultObject) => {
        const updatedProduct = parsedProducts.find(
          (product) => product.handle === resultObject.product.handle
        );
        return {
          ...resultObject,
          product: updatedProduct,
        };
      });

    sendEvent({
      email,
      eventName: 'Quiz Save Results',
      metricName: 'Quiz Save Results',
      eventProperties: {
        quizResultUrl: parseQuizResultUrl(),
        products: parsedResultProducts.map((resultObject) => {
          const customCollectionMetafield = resultObject?.product?.metafields?.find((metafield) => metafield.key === 'custom_collection_title');
          const customTitleMetafield = resultObject?.product?.metafields?.find((metafield) => metafield.key === 'custom_product_title');
          return {
            title: customTitleMetafield?.value || resultObject?.product?.title,
            collection: customCollectionMetafield?.value || '',
            url: resultObject?.product?.onlineStoreUrl,
            image: resultObject?.product?.featuredImage?.src,
            problem: resultObject?.resultMessage,
          }
        })
      },
      profileProperties: customerProperties,
      callback: (response) => {
        if(typeof response == 'string') {
          setSaveResultsMessage('Your results have been saved!');
        } else {
          setSaveResultsMessage(response.errors[0].detail);
          setSaveResultError(true);
        }
      }
    })
  }

  useEffect(() => {
    updateQuizData({
      ...quizData,
      sentAnaliticsEvent: true,
    })
    if(!quizData.loadedFromUrl) {
      if(typeof window !== 'undefined') {
        history.replaceState('', null, `${document.location.protocol}//${parseQuizResultUrl()}`);
      }

      const answersMap = quizData.answers.map((answer) => {
        return `${answer.questionIndex}:${Array.isArray(answer.answer) ? answer.answer.join(',') : answer.answer}`
      })
      const datalayer = window.dataLayer || [];
      datalayer.push({
        'event': 'Quiz - View Results',
        'Quiz Question Data': answersMap.join('|'),
      })
    }
  }, []);

  return (
    <div>
      <div className="quiz-result__header mx-auto w-[720px] max-w-full px-5 pt-[78px] pb-[60px] text-center">
        <h1 className="h4 mb-5 font-tiempos"> {quizSettings.resultTitle} </h1>
        <div>
          {quizSettings.resultDescription}
          <span className="quiz-result__info-container relative inline-block">
            <button
              type="button"
              onClick={toggleResultSummary}
              className="ml-1 align-middle"
            >
              <div className="sr-only">Open summary</div>
              <Info className="h-5 w-5" />
              {summaryOpen && isMobile && (
                <span className="absolute left-1/2 -bottom-1/2 z-20 mb-[-1px] h-2.5 w-2.5 -translate-x-1/2 rotate-45 border-t border-l border-grey-9 bg-white"></span>
              )}
            </button>
            {summaryOpen && !isMobile && summaryPopup}
          </span>
          {summaryOpen && isMobile && summaryPopup}
        </div>
        {quizSettings.addAllTop && (
          <div className="text-center mt-5 lg:mt-8">
            <button
              type="button"
              className="quiz-result__add-all-products-btn button button--primary"
              onClick={addAllProductsToCart}
            >
              {quizSettings.addAllLabel}
            </button>
          </div>
        )}
      </div>
      <div className="quiz-result__body-content bg-grey-9 py-12">
        {dailyProducts.length > 0 && (
          <div
            className={`quiz-result__daily-routine-group lg-px-0 mx-auto w-[720px] max-w-full border-[#979797] px-5 ${
              dailyProducts.length &&
              specializedProducts.length &&
              'lg:border-b'
            } `}
          >
            <div className="quiz-result__daily-routine-header text-center">
              <h2 className="quiz-result__routine-title h3 mb-2">
                {quizSettings.baseRoutineTitle}
              </h2>
              <Markdown content={quizSettings.baseRoutineDescription} />
            </div>
            <div
              className={`quiz-result__product-grid flex flex-wrap mt-6 lg:px-0 ${
                dailyProducts.length % 2 !== 0 &&
                Styles['quiz-result__product-grid--odd']
              }`}
            >
              {dailyProducts.map((productData, index) => (
                <div className="grid-item mb-6 w-full border-t border-[#979797] pt-7 first:border-t-0 lg:w-6/12 lg:border-t-0 lg:border-none lg:pt-0 lg:odd:pr-10 lg:even:pl-10">
                  <QuizProduct
                    productData={productData}
                    index={index}
                    key={productData.product.id}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {specializedProducts.length > 0 && (
          <div className="quiz-result__specialized-routine-group lg-px-0 mx-auto mt-12 w-[720px] max-w-full px-5">
            <div className="quiz-result__daily-routine-header text-center">
              <h2 className="quiz-result__routine-title h3 mb-2">
                {quizSettings.specializedRoutineTitle}
              </h2>
              <Markdown content={quizSettings.specializedRoutineDescription} />
            </div>
            <div
              className={`quiz-result__product-grid flex flex-wrap mt-6 lg:px-0 ${
                specializedProducts.length % 2 !== 0 &&
                Styles['quiz-result__product-grid--odd']
              }`}
            >
              {specializedProducts.map((productData, index) => (
                <div className="grid-item mb-6 w-full border-t border-[#979797] pt-7 first:border-t-0 lg:w-6/12 lg:border-t-0 lg:border-none lg:pt-0 lg:odd:pr-10 lg:even:pl-10">
                  <QuizProduct
                    productData={productData}
                    index={index}
                    key={productData.product.id}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="quiz-result__cta-container my-14 px-4 text-center">
        {!quizSettings.addAllTop && (
          <>
            <h3 className="mx-auto max-w-lg">
              Want it all? Add your entire customized routine to cart!
            </h3>
            <div>
              <button
                type="button"
                className="quiz-result__add-all-products-btn button button--primary"
                onClick={addAllProductsToCart}
              >
                {quizSettings.addAllLabel}
              </button>
            </div>
          </>
        )}
        {quizSettings.enableSaveResults && (
          <>
            {!quizSettings.addAllTop && (
              <span className="my-2 block">or</span>
            )}
            <div>
              <button
                type="button"
                className="quiz-result__save-results-btn button button--checkout"
                onClick={toggleSaveResultsModal}
              >
                {quizSettings.saveResultsLabel}
              </button>
            </div>
          </>
        )}
        <div className="mt-4">
          <button
            type="button"
            className="focus-link mx-auto !font-primary !border-b-[1px]"
            onClick={() => {
              history.replaceState(null, '', window.location.pathname);

              updateQuizData({
                quizStep: 'steps',
                currentQuestion: 0,
                answers: [],
                isFiltered: false,
                hasError: false,
              });
            }}
          >
            retake the quiz
          </button>
        </div>
      </div>
      <div className="quiz-result__banner container relative my-14 mx-auto lg:px-4">
        <div className="quiz-result__banner-image">
          {isMobile ? (
            <Image
              src={quizSettings.bannerImageMobile?.src}
              alt={quizSettings.bannerImageMobile?.alt}
              width={quizSettings.bannerImageMobile?.width}
              height={quizSettings.bannerImageMobile?.height}
              className="w-full"
            />
          ) : (
            <Image
              src={quizSettings.bannerImageDesktop?.src}
              alt={quizSettings.bannerImageDesktop?.alt}
              width={quizSettings.bannerImageDesktop?.width}
              height={quizSettings.bannerImageDesktop?.height}
              className="w-full"
            />
          )}
        </div>
        <div
          className={`quiz-result__banner-info top-1/2 mt-8 px-5 text-center lg:absolute lg:ml-20 lg:mt-0 lg:max-w-[344px] lg:-translate-y-1/2 lg:px-0 lg:text-left ${
            quizSettings.desktopBannerLightText ? 'lg:text-white' : 'text-black'
          }`}
        >
          {quizSettings.resultBannerTitle && (
            <h3 className="mb-0 text-h3-m lg:text-h2">
              {quizSettings.resultBannerTitle}
            </h3>
          )}
          {quizSettings.resultBannerBody && (
            <Markdown
              content={quizSettings.resultBannerBody}
              className="mt-5 last:mb-0 lg:mt-8"
            />
          )}
          {quizSettings.resultBannerLink?.url &&
            quizSettings.resultBannerLink?.text && (
              <Link href={quizSettings.resultBannerLink?.url}>
                <a
                  className={`button button--secondary mt-5 inline-block no-underline lg:mt-8 ${
                    quizSettings.desktopBannerLightText &&
                    'lg:!border-white lg:!text-white lg:hover:!bg-white lg:hover:!text-black'
                  }`}
                  target={
                    quizSettings.resultBannerLink.newTab ? '_blank' : '_self'
                  }
                >
                  {quizSettings.resultBannerLink?.text}
                </a>
              </Link>
            )}
        </div>
      </div>
      {quizSettings.enableSaveResults && saveResultsModalOpen && (
        <div className="save-results-modal fixed top-0 z-[100001] flex h-full w-full items-center justify-center">
          <div className="quiz-help-modal__body h-auto max-h-[80%] max-w-xl overflow-y-auto bg-white px-5 py-6">
            <div className="w-full text-right">
              <button
                type="button"
                label="Close Info Modal"
                onClick={toggleSaveResultsModal}
              >
                <Close />
              </button>
            </div>
            <h2 className="h5 mb-5 text-center font-tiempos">
              Save Your Results
            </h2>
            <p>
              Introduce your email to get your results straight to your inbox!
            </p>
            <form action="#" onSubmit={sendResultsToEmail}>
              <input
                type="email"
                name="save-results-email"
                placeholder="E-mail Address"
                label="E-mail Address"
                className="w-full border border-grey-5 py-2 px-4 placeholder-black"
                ref={emailField}
                onChange={() => {
                  setSaveResultsMessage('');
                }}
              />
              {saveResultsMessage && (
                <p
                  className={`mt-2 text-left text-sm mb-0 ${
                    saveResultError ? 'text-error' : 'text-success'
                  }`}
                >
                  {saveResultsMessage}
                </p>
              )}
              <button
                type="submit"
                className="button button--primary mt-5 w-full"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
