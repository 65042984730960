import { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Markdown } from '@/snippets/globals/layout/Markdown';
import { Image } from '@/snippets/components/Image';
import { useWindowDimensions } from '@/utils/Helper';
import { HorizontalCard } from '@/snippets/globals/HorizontalCard';
import { IconArrowLeft, IconArrowRight } from '@/snippets/index';
import { Play } from '@/snippets/icons/Play';
import { Pause } from '@/snippets/icons/Pause';
import { Mute } from '@/snippets/icons/Mute';
import { Unmute } from '@/snippets/icons/Unmute';
import { Info } from '@/snippets/icons/Info';
import { Close } from '@/snippets/icons/Close';

export function Testimonial ({cms}) {
  const {
    title,
    subtitle,
    bgColor,
    lightText,
    testimonials,
  } = cms;
  const testimonialSlider = useRef(null);
  const sliderContainer = useRef(null);
  const tabsContainer = useRef(null);
  const { vpWidth } = useWindowDimensions();
  const isMobile = vpWidth < 768;
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [previousSlideIndex, setPreviousSlideIndex] = useState(0);
  const [mobileStatsOpen, setMobileStatsOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  const sliderSettings = {
    slidesToShow: 1,
    fade: true,
    infinite: true,
    arrows: false,
    lazyLoad: 'ondemand',
    slide: '.slide',
    adaptiveHeight: true,
    beforeChange: (currentSlide, nextSlide) => {
      setActiveSlideIndex(nextSlide)
      setPreviousSlideIndex(currentSlide)
    },
  }

  const togglePlay = () => {
    const activeSlideVideo = sliderContainer?.current?.querySelector(`.slick-slide[data-index="${activeSlideIndex}"] video`);

    if(activeSlideVideo) {
      if(isPlaying) {
        activeSlideVideo.pause();
        setIsPlaying(false);
      } else {
        activeSlideVideo.play();
        setIsPlaying(true);
      }
    }
  }

  const toggleAudio = () => {
    const activeSlideVideo = sliderContainer?.current?.querySelector(`.slick-slide[data-index="${activeSlideIndex}"] video`);

    if(activeSlideVideo) {
      if (isMuted) {
        activeSlideVideo.muted = false;
        setIsMuted(false);
      } else {
        activeSlideVideo.muted = true;
        setIsMuted(true);
      }
    }
  }

  const toggleMobileStats = () => {
    setMobileStatsOpen(!mobileStatsOpen);
  }

  const buildSliderTabs = () => {
    const sliderTabs = testimonials.map((testimonial, index) => {
      const activeClasses = lightText ? 'bg-white text-black' : 'bg-black text-white';
      return (
        <button
          key={`tabButton-${index}`}
          type="button"
          className={`border-2 rounded-full px-4 py-1 text-xs font-gothamBold transition-colors whitespace-nowrap ${activeSlideIndex === index && activeClasses}
            ${lightText ? 'border-white hover:bg-white hover:text-black': 'border-black hover:bg-black hover:text-white'}`}
          onClick={() => {
            testimonialSlider.current.slickGoTo(index);
          }}
        >
          {testimonial.tabTitle}
        </button>
      );
    })

    return sliderTabs;
  }

  useEffect(() => {
    if(tabsContainer?.current && tabsContainer?.current?.scrollWidth > tabsContainer?.current?.clientWidth ) {
      tabsContainer?.current?.classList.remove('justify-center');
      tabsContainer?.current?.classList.add('justify-start');
    } else {
      tabsContainer?.current?.classList.add('justify-center');
      tabsContainer?.current?.classList.remove('justify-start');
    }
  }, [vpWidth])

  useEffect(() => {
    const previousSlideVideo = sliderContainer?.current?.querySelector(`.slick-slide[data-index="${previousSlideIndex}"] video`);
    const activeSlideVideo = sliderContainer?.current?.querySelector(`.slick-slide[data-index="${activeSlideIndex}"] video`);

    if(previousSlideVideo) {
      previousSlideVideo.pause();
      previousSlideVideo.muted = true
      setIsMuted(true);
    }

    if(activeSlideVideo) {
      activeSlideVideo.play();
      setIsPlaying(true);
    }
  }, [activeSlideIndex])


  return (
    <section
      key={cms.id}
      id={cms.id}
      className={`py-14 lg:py-24 ${bgColor} ${
        lightText === true ? 'text-white' : 'text-black'
      }`}
    >
      <div className="testimonial__container container mx-auto px-4 lg:px-24">
        <div className="testimonial__header">
          {title && (
            <h2 className="testimonial__title mb-3 text-center font-tiempos text-[22px] md:text-h4">
              {title}
            </h2>
          )}
          {subtitle && (
            <p className="testimonial__subtitle font-gothamLight mx-auto mb-6 max-w-[440px] text-center text-base md:text-lg">
              {subtitle}
            </p>
          )}
        </div>
        {testimonials?.length > 1 && (
          <div
            className="testimonial__tabs-indicators -mx-4 flex justify-center gap-3 overflow-auto px-4"
            ref={tabsContainer}
          >
            {buildSliderTabs()}
          </div>
        )}
        <div className="testimonial-body relative mt-10 md:mt-15" ref={sliderContainer}>
          {testimonials?.length > 0 && (
            <Slider
              className="testimonial__carousel mx-auto max-w-6xl"
              ref={testimonialSlider}
              {...sliderSettings}
            >
              {testimonials.map((testimonial, index) => (
                <div key={`testimonial-slide-${index}`} className="slide">
                  <div
                    className={`testimonial__slide flex w-full flex-col md:flex-row md:border ${
                      lightText ? 'md:border-white' : 'md:border-black'
                    }`}
                  >
                    <div className="testimonial__graphic relative aspect-[9/16] w-full flex-shrink-0 md:w-5/12">
                      {testimonial?.video && (
                        <>
                          <video
                            className="absolute z-[1] h-full w-full object-cover"
                            loop
                            muted
                            playsInline
                            loading="lazy"
                          >
                            <source src={testimonial.video} />
                          </video>
                          <button
                            type="button"
                            className="testimonial__play-button absolute right-4 bottom-[98px] z-[2] text-black"
                            onClick={togglePlay}
                          >
                            {isPlaying ? (
                              <Pause className="h-16 w-16" />
                            ) : (
                              <Play className="h-16 w-16" />
                            )}
                            <div className="sr-only">
                              {isPlaying ? 'Pause' : 'Play'}
                            </div>
                          </button>
                          <button
                            type="button"
                            className="testimonial_audio-button absolute right-4 bottom-5 z-[2] text-black"
                            onClick={toggleAudio}
                          >
                            {isMuted ? (
                              <Unmute className="h-16 w-16" />
                            ) : (
                              <Mute className="h-16 w-16" />
                            )}
                            <span className="sr-only">
                              {isMuted ? 'Unmute' : 'Mute'}
                            </span>
                          </button>
                        </>
                      )}
                      {testimonial.image && (
                        <Image
                          className="block w-full"
                          src={testimonial.image.src}
                          alt={testimonial.image.alt}
                          fill
                        />
                      )}
                    </div>
                    <div
                      className={`testimonial__content flex w-full flex-col md:border-l ${
                        lightText ? 'md:border-white' : 'md:border-black'
                      }`}
                    >
                      {!!testimonial?.quote && (
                        <div
                          className={`testimonial__quote flex h-full flex-col justify-center py-4 px-0 font-tiempos md:border-b md:py-8 md:pl-[72px] md:pr-[64px] ${
                            lightText ? 'md:border-white' : 'md:border-black'
                          } `}
                        >
                          <p className="mb-0 text-h3-m lg:text-h4">
                            {testimonial.quote}
                          </p>
                          <p className="mt-2 mb-0 font-gothamBold text-lg lg:mt-6 lg:text-h5">
                            {testimonial.name}
                            {testimonial.instagramHandle && (
                              <a
                                href={`https://instagram.com/${testimonial.instagramHandle.replace(
                                  '@',
                                  ''
                                )}`}
                                target="_blank"
                                className={`ml-2 ${
                                  lightText ? 'focus-link-white' : 'focus-link'
                                }`}
                              >
                                {testimonial.instagramHandle}
                              </a>
                            )}
                            {isMobile && !!testimonial?.stats && (
                              <button
                                type="button"
                                className="testimonial__info-button relative ml-2 align-middle"
                                onClick={toggleMobileStats}
                              >
                                {mobileStatsOpen && (
                                  <span className="absolute left-1/2 -top-3 h-4 w-4 -translate-x-1/2 -translate-y-full rotate-45 bg-white"></span>
                                )}
                                <Info className="h-5 w-5" />
                              </button>
                            )}
                            {isMobile && mobileStatsOpen && testimonial?.stats && (
                              <div className="testimonial-stat-container relative overflow-visible">
                                <div
                                  className={`testimonial__stats-mobile absolute inset-0 -top-[3.5em] z-50 min-h-min -translate-y-full bg-white p-5 pb-0 pr-16 font-primary text-xs text-black`}
                                >
                                  <button
                                    type="button"
                                    className="testimonial__info-button absolute top-5 right-5"
                                    onClick={toggleMobileStats}
                                  >
                                    <Close />
                                  </button>
                                  <Markdown content={testimonial.stats} />
                                </div>
                              </div>
                            )}
                          </p>
                        </div>
                      )}
                      {!isMobile && !!testimonial?.stats && (
                        <div className="testimonial__stats flex h-full flex-col justify-center py-8 pl-[72px] pr-[64px]">
                          <Markdown content={testimonial.stats} />
                        </div>
                      )}
                      {!!testimonial?.featuredProduct && (
                        <div className={`testimonial__product mt-auto mb-[1px] md:border-t bg-white text-black lg:mb-0 ${
                          lightText ? 'md:border-white' : 'md:border-black'
                        }`}>
                          <HorizontalCard
                            productObject={testimonial.featuredProduct}
                            imageClassNames="lg:!w-[215px]"
                            buttonClassNames="button--checkout"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          )}
          {testimonials?.length > 1 && (
            <div className="relative mt-7 flex justify-end lg:static">
              <button
                type="button"
                className={`testimonial__prev-arrow z-1 h-[45px] w-[45px] border text-center lg:absolute lg:top-1/2 lg:-left-16 lg:-translate-y-1/2 transition-colors mr-[-1px] ${
                            lightText ? 'border-white hover:text-black hover:bg-white' : 'border-black hover:text-white hover:bg-black'
                          } `}
                onClick={() => testimonialSlider.current.slickPrev()}
              >
                <IconArrowLeft className="mx-auto w-2.5" />
                <span className="sr-only">Previous</span>
              </button>
              <button
                type="button"
                className={`testimonial__next-arrow z-1 h-[45px] w-[45px] border text-center lg:absolute lg:top-1/2 lg:-right-16 lg:-translate-y-1/2 transition-colors ${
                            lightText ? 'border-white hover:text-black hover:bg-white' : 'border-black hover:text-white hover:bg-black'
                          } `}
                onClick={() => testimonialSlider.current.slickNext()}
              >
                <IconArrowRight className="mx-auto w-2.5" />
                <span className="sr-only">Next</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

Testimonial.displayName = 'Testimonial';

Testimonial.Schema = {
  category: 'Modules',
  label: Testimonial.displayName,
  key: 'Testimonial',
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'textarea',
    },
    {
      name: 'bgColor',
      label: 'Background Color',
      component: 'select',
      description:
        'For section background color, select one from preselected brand colors.',
      options: [
        { label: 'White', value: 'bg-white' },
        { label: 'Grey', value: 'bg-grey-1' },
        { label: 'Light Grey', value: 'bg-grey-9' },
        { label: 'Blue 1', value: 'bg-quinary-1' },
        { label: 'Blue 2', value: 'bg-quinary-2' },
        { label: 'Blue 3', value: 'bg-light-blue' },
        { label: 'Cream 1', value: 'bg-tertiary-2' },
        { label: 'Cream 2', value: 'bg-senary-4' },
        { label: 'Purple', value: 'bg-tertiary-1' },
        { label: 'Green', value: 'bg-quaternary-1' },
        { label: 'Pink 1', value: 'bg-primary-2' },
        { label: 'Pink 2', value: 'bg-secondary-1' },
        { label: 'Yellow', value: 'bg-yellow' },
        { label: 'Orange', value: 'bg-secondary-2' },
        { label: 'Seafoam 1', value: 'bg-quaternary-2' },
        { label: 'Seafoam 2', value: 'bg-senary-3' },
        { label: 'Air Dry Cream', value: 'bg-stylers-1' },
        { label: 'Sculpting Cream', value: 'bg-stylers-2' },
        { label: 'Texture Foam', value: 'bg-stylers-3' },
        { label: 'Sleek Stick', value: 'bg-stylers-4' }
      ],
      defaultValue: 'bg-white',
    },
    {
      name: 'lightText',
      label: 'Text Color',
      component: 'toggle',
      toggleLabels: {
        true: 'Light',
        false: 'Dark'
      },
    },
    {
      name: 'testimonials',
      label: 'Testimonials',
      component: 'group-list',
      itemProps: {
        label: 'Testimonial: {{item.name}}'
      },
      fields: [
        {
          name: 'tabTitle',
          label: 'Tab Title',
          component: 'text',
          description: 'Title for the testimonial tab'
        },
        {
          name: 'image',
          label: 'Image',
          component: 'image',
          description: 'Recomended size: 885px x 498px (or equivalent aspect ratio)'
        },
        {
          name: 'video',
          label: 'Video',
          component: 'text',
          description: 'Recommended size: 885px x 498px (or equivalent aspect ratio) format MP4'
        },
        {
          name: 'quote',
          label: 'Quote',
          component: 'textarea',
        },
        {
          name: 'name',
          label: 'Reviewer Name',
          component: 'text',
        },
        {
          name: 'instagramHandle',
          label: 'Reviewer Instagram Handle',
          component: 'text',
        },
        {
          name: 'stats',
          label: 'Reviewer Statistics',
          component: 'markdown',
          description: 'Details about the client\'s testimonial, this content is placed in a pop up window on mobile resolutions so consider the length of the content.'
        },
        {
          name: 'featuredProduct',
          label: 'Featured product',
          component: 'productSearch',
          description: 'Product featured by this testimonial',
        },
      ]
    }
  ]
}
