import {
  useSettings,
  useRouter,
  useCountry,
  useCustomer,
  useCartCount,
  useStorefrontInit,
} from '@backpackjs/storefront';
import { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import {
  IconMenu,
  IconSearch,
  IconAccount,
  IconCart,
  IconClose,
} from '@/snippets/index';
import NavDesktop from '@/snippets/components/NavDesktop';
import NavMobile from '@/snippets/components/NavMobile';
import SwellPoints from '@/snippets/components/SwellPoints';
import MobileQuickLinks from './mobileQuickLinks';
import SideCart from '@/snippets/sideCart/SideCart';
import Search from '@/snippets/search/Search';
import DiscountCodeFromUrl from '@/snippets/components/DiscountCodeFromUrl';


function Header() {
  const settings = useSettings();
  const { route, events } = useRouter();
  const item_count = useCartCount();
  const customer = useCustomer();
  const HeaderEl = useRef(null);
  const headerFiller = useRef(null);
  const { country } = useCountry();
  const countryCode = country?.isoCode || 'US';
  const isUs = countryCode === 'US';
  const is_home = route.pathname === '/';
  const [scrollPosition, setScrollPosition] = useState(0);
  const headerOffset = typeof window !== 'undefined' ? HeaderEl.current?.offsetHeight - window.scrollY : 1;

  let mainMenu = settings?.header_menu || [];
  mainMenu = mainMenu.map((item, itemIndex, menuArr) => {
    const newItem = { link: item.link, column: [] };
    const columns = item.column
      ? item.column.filter((col, index) => {
          return (
            (col?.cta || col?.menu) && col?.enable_column === true && index <= 3
          );
        })
      : [];
    newItem.column = columns;
    return newItem;
  });

  const [searchOpen, setSearchOpen] = useState(false);
  const [displayAnnouncement, setDisplayAnnouncement] = useState(true);
  const [navOpen, setNavOpen] = useState(false);
  const [sideCartOpen, setSideCartOpen] = useState(false);

  const closeSearch = (evt) => {
    evt?.preventDefault();
    setSearchOpen(false);
  };

  const jsonSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: useStorefrontInit()?.shop?.name,
    url: process.env.SITE_URL,
    logo: settings?.header?.logo?.src,
  };

  const scrollLock = () => {
    const { body } = document;
    const currentScroll = window.scrollY;
    body.style.setProperty('--topPosition', `-${currentScroll}px`);
    body.classList.add('overflow-hidden', 'bg-overlay');
  };

  const scrollUnlock = () => {
    const { body } = document;
    const scrollTop = body.style.getPropertyValue('--topPosition');
    body.classList.remove('overflow-hidden', 'bg-overlay');
    body.style.removeProperty('--topPosition');

    if (scrollTop !== '') {
      window.scrollTo({
        top: parseInt(scrollTop.replace('px', '') || '0', 10) * -1,
      });
    }
    window.lastSpot = parseInt(scrollTop.replace('px', '') || '0', 10) * -1;
  };

  const closeNav = () => {
    setNavOpen(false);
    scrollUnlock();
  }

  const toggleNav = () => {
    if (navOpen) {
      scrollUnlock();
      setNavOpen(false);
    } else {
      scrollLock();
      setNavOpen(true);
    }
  };

  const toggleSideCart = (evt) => {
    setSideCartOpen((oldSidecartOpen) => {
      if (oldSidecartOpen) {
        scrollUnlock();
      } else {
        scrollLock();
      }

      return !oldSidecartOpen;
    })
  };

  const openSideCart = () => {
    if (!sideCartOpen){
      setSideCartOpen(true);
      scrollLock();
    }

  };

  const closeSideCart = () => {
    setSideCartOpen(false);
    scrollUnlock();
  };

  const toggleSearch = () => {
    if (!searchOpen) {
      setTimeout(() => {
        const input = document.querySelector('#search-input');
        input?.focus();
      }, 250);
    }
    setSearchOpen(!searchOpen);
  };

  const closeNavDrawers = () => {
    closeNav();
    closeSearch();
    closeSideCart();
    scrollUnlock();

    const megaMenus = HeaderEl.current.querySelectorAll('.mega-menu');
    const dropdownMenus = HeaderEl.current.querySelectorAll('.dropdown-menu');
    megaMenus.forEach((menu) => {
      menu.classList.add('!pointer-events-none');
    });
    dropdownMenus.forEach((menu) => {
      menu.classList.add('!pointer-events-none');
    });
  };

  const scrollHandler = () => {
    setScrollPosition(window.scrollY);
  };

  const restoreMainNav = () => {
    const megaMenus = HeaderEl.current.querySelectorAll('.mega-menu');
    const dropdownMenus = HeaderEl.current.querySelectorAll('.dropdown-menu');
    megaMenus.forEach((menu) => {
      menu.classList.remove('!pointer-events-none');
    });
    dropdownMenus.forEach((menu) => {
      menu.classList.remove('!pointer-events-none');
    });
  };

  useEffect(() => {
    if (!window.lastSpot) window.lastSpot = window.scrollY;

    window.addEventListener('openSideCart', openSideCart);
    window.addEventListener('closeSideCart', restoreMainNav);
    window.addEventListener('scroll', scrollHandler, {passive: true});
    return () => {
      window.removeEventListener('openSideCart', openSideCart);
      window.removeEventListener('closeSideCart', restoreMainNav);
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  useEffect(() => {
    events.on('routeChangeStart', closeNavDrawers);
    events.on('routeChangeComplete', restoreMainNav);
    return () => {
      events.off('routeChangeStart', closeNavDrawers);
      events.off('routeChangeComplete', restoreMainNav);
    };
  }, [events]);

  useEffect(() => {
    const body = document.querySelector('body');
    if(headerFiller.current.offsetHeight !== HeaderEl.current.offsetHeight) {
      headerFiller.current.style.height = `${HeaderEl.current.offsetHeight}px`;
    }

    if(body?.className?.indexOf('bg-overlay') > -1) return;

    if(scrollPosition > headerOffset && !HeaderEl.current.classList.contains('!fixed')) {
      HeaderEl.current.classList.add('!fixed');
      headerFiller.current.classList.add('!block');
      return;
    }

    if(scrollPosition < headerOffset && HeaderEl.current.classList.contains('!fixed')) {
      HeaderEl.current.classList.remove('!fixed');
      headerFiller.current.classList.remove('!block');
    }
  }, [scrollPosition])

  return (
    <>
      <header
        className="site-header peer top-0 z-[2000] w-full border-b border-grey-7 bg-white transition-all"
        ref={HeaderEl}
      >
        <div className="items-center1 relative z-0 flex justify-between py-4 px-4 md:px-12 lg:py-5">
          <div className="flex flex-1 lg:hidden">
            <button
              type="button"
              className="ml-0 block lg:hidden"
              onClick={() => toggleNav()}
            >
              <IconMenu />
              <span className="sr-only">Menu toggle</span>
            </button>
            <button
              type="button"
              className="ml-4 mr-4 block md:ml-6 lg:hidden"
              onClick={toggleSearch}
            >
              {searchOpen ? <IconClose /> : <IconSearch />}
              <span className="sr-only">Toggle search</span>
            </button>
          </div>
          <div className="flex flex-none flex-1 md:flex-grow-0">
            {is_home ? (
              <h1 className="header__logo mx-5 mb-0 md:mx-0 lg:px-0">
                <Logo settings={settings} />
              </h1>
            ) : (
              <div className="header__logo mx-5 mb-0 md:mx-0 lg:px-0">
                <Logo settings={settings} />
              </div>
            )}
          </div>

          <NavDesktop menu={mainMenu} />

          <div className="flex flex-1 items-center justify-end lg:flex-grow-0">
            <div className="header__icons flex items-center">
              {settings?.header?.cta?.link && !settings?.header?.rewards_enabled ? (
                  <p className={`mt-0 mr-9 mb-0 hidden lg:block`}>
                    <Link href={settings?.header?.cta?.link?.url}>
                      <a className={`${settings?.header?.cta?.style} text-center no-underline !text-sm`}>
                        {settings?.header?.cta?.link?.text}
                      </a>
                    </Link>
                  </p>
                ) : null}

              {isUs && settings?.header?.rewards_enabled ? (
                <div>
                  {!customer ? (
                    <Link href="/pages/rewards">
                      <a className="button button--primary mr-9 hidden whitespace-nowrap lg:min-w-[100px] xl:min-w-[200px] !px-5 !py-2 !text-sm no-underline lg:block">
                        earn rewards
                      </a>
                    </Link>
                  ) : (
                  <Link href="/pages/rewards">
                    <a
                      className="button button--primary mr-9 hidden whitespace-nowrap lg:min-w-[100px] xl:min-w-[200px] !px-5 !py-2 !text-sm no-underline lg:block"
                    >
                        redeem rewards
                        <SwellPoints key={customer.id} />
                      </a>
                    </Link>
                  )}
                </div>
              ) : null}

              <button
                type="button"
                className="mr-4 hidden hover:text-grey-3 md:mr-7 lg:block"
                onClick={toggleSearch}
              >
                {searchOpen ? <IconClose /> : <IconSearch />}
                <span className="sr-only">Toggle search</span>
              </button>
              <Link href={`${customer ? '/account' : '/account/login'}`}>
                <a
                  href={`${customer ? '/account' : '/account/login'}`}
                  className="text-black hover:text-grey-3"
                >
                  <IconAccount />
                  <span className="sr-only"> Account Login </span>
                </a>
              </Link>
              <Link href="/cart">
                <a
                  aria-label="open cart"
                  className="header__icon-cart relative z-0 ml-4 mr-0 flex items-center text-black no-underline hover:text-grey-3 md:ml-7"
                  href="/cart"
                  onClick={(evt) => {
                    evt.preventDefault();
                    toggleSideCart(evt);
                  }}
                >
                  <IconCart />
                  <span
                    className={`cart-count ml-2 align-middle md:ml-2 ${
                      item_count > 0 ? '' : 'hidden'
                    }`}
                  >
                    <span data-cart-count className="p-1 align-middle">
                      {item_count}
                    </span>
                  </span>
                </a>
              </Link>
            </div>
          </div>
        </div>

        <Search {...{ searchOpen, closeSearch }} />
        <DiscountCodeFromUrl />
        <MobileQuickLinks />
      </header>
      <div className="header-filler hidden" ref={headerFiller}/>
      <NavMobile menu={mainMenu} navOpen={navOpen} closeNav={closeNav} />
      <SideCart sideCartOpen={sideCartOpen} toggleSideCart={toggleSideCart} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonSchema) }}
      />
    </>
  );
}

function Logo({ settings }) {
  return (
    settings?.header?.logo && (
      <Link href="/">
        <a className="logo-image m-auto block w-logo-m no-underline lg:w-logo">
          <img
            src={settings?.header?.logo?.src}
            loading="lazy"
            className="h-full w-full"
            alt={settings?.header?.tagline}
          />
          <span className="visually-hidden">{settings?.header?.tagline}</span>
        </a>
      </Link>
    )
  );
}

export default Header;