import { useMemo, useEffect, useRef, useState } from 'react';
import { useProductsFromHandles, useSettings } from '@backpackjs/storefront';
import { useRouter } from '@backpackjs/storefront';
import FilterBar from '@/snippets/globals/layout/FilterBar';
import ProductGrid from '@/snippets/globals/layout/ProductGrid';
import Pagination from '@/snippets/globals/layout/Pagination';
import CollectionHeader from '@/snippets/components/CollectionHeader';
import LoadMore from '@/snippets/globals/layout/LoadMore';
import { useDataLayerActions } from '@hooks';

function Section({ cms, collection }) {
  const router = useRouter();

  const settings = useSettings();
  const { campaigns } = { ...settings?.collectionTile };

  const fullProducts = collection?.products;

  const productsPerPage = 24;
  const { sendViewCollectionEvent } = useDataLayerActions();

  const [layoutParams, setLayoutParams] = useState({
    filters: router.query.filters
      ? typeof router.query.filters === 'string'
        ? [router.query.filters]
        : router.query.filters
      : [],
    sort: router.query.sort || 'def:def',
    page: router.query.page || 1,
  });

  const promoTiles = useMemo(() => {
    if (!campaigns?.length) return [];
    const campaign = campaigns.find(( campaign ) => {
      if (!campaign?.enabled) {
        return false;
      }
      return campaign?.collections?.some((colHandle) => colHandle.trim() === collection?.handle);
    });
    return campaign?.promoTiles || [];
  }, [collection, campaigns]);


  const getLoadedPageCTAs = () => {
    const currentPageCTAs = promoTiles?.filter((tile) => {
      if (cms.enableLoadMore) {
        return Math.ceil(tile.position / productsPerPage) <= layoutParams.page;
      }
      return Math.ceil(tile.position / productsPerPage) == layoutParams.page;
    })
    const loadedPreviousCTAs = promoTiles?.filter((tile) => {
      if (cms.enableLoadMore){
        return false;
      }

      return(Math.ceil(tile.position / productsPerPage) < layoutParams.page);
    });

    return {currentPageCTAs, loadedPreviousCTAs};
  }

  const tileCtas = getLoadedPageCTAs();

  const sortItems = (products = []) => {
    const sortPieces = layoutParams?.sort?.split(':') || [];
    const sortedItems = [...products].sort((a, b) => {
      switch (sortPieces[0]) {
        case 'price':
          if (sortPieces[1] === 'asc') {
            return (a.priceRange.min || 0) - (b.priceRange.min || 0);
          }
          return (b.priceRange.max || 0) - (a.priceRange.max || 0);

        case 'alpha':
          if (sortPieces[1] === 'asc') {
            return a.title.toUpperCase() < b.title.toUpperCase() ? '-1' : '1';
          }
          return a.title.toUpperCase() > b.title.toUpperCase() ? '-1' : '1';
        default:
          return 0;
      }
    });
    return sortedItems;
  };

  const applyFilters = () => {
    let filteredProducts = fullProducts;

    if (layoutParams?.filters?.length > 0) {
      filteredProducts = collection?.products?.filter((product) => {
        return product?.tags.some((tag) => layoutParams.filters.includes(tag));
      });
    }

    return filteredProducts || [];
  };

  const collectionContainer = useRef();
  const products = applyFilters();
  const sortedItems = sortItems(products) || [];
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);

  const getCurrentPageItems = () => {
    let currentPageItems = [];
    const productList = [...sortedItems];
    currentPageItems = cms?.enableLoadMore
      ? productList.slice(
          0,
          productsPerPage * (layoutParams?.page || 1) -
            tileCtas.loadedPreviousCTAs.length -
            tileCtas.currentPageCTAs.length
        )
      : productList.slice(
          productsPerPage * ((layoutParams?.page || 1) - 1) -
            tileCtas.loadedPreviousCTAs.length,
          productsPerPage * (layoutParams?.page || 1) -
            tileCtas.loadedPreviousCTAs.length -
            tileCtas.currentPageCTAs.length
        );

    if (tileCtas.currentPageCTAs.length) {
      tileCtas.currentPageCTAs.forEach((item) => {
        let pageCorrection = 0;
        if (layoutParams.page > 1 && !cms.enableLoadMore) {
          pageCorrection = productsPerPage * (layoutParams.page - 1);
        }
        currentPageItems.splice(item.position - 1 - pageCorrection, 0, item)
      })
    }
    return currentPageItems;
  };

  const currentPageItems = getCurrentPageItems();

  const scrollEffectParams = !cms?.enableLoadMore
    ? [layoutParams]
    : [layoutParams.filters.length, layoutParams.sort];

  useEffect(() => {
    if (typeof document === 'undefined') return undefined;
    const { offsetTop } = collectionContainer.current;
    const headerHeight = document.querySelector('.site-header').offsetHeight;

    if (window.scrollY > offsetTop && !window.location.hash) {
      window.scrollTo({
        top: offsetTop - headerHeight,
        behavior: 'smooth',
      });
    }
  }, scrollEffectParams);

  useEffect(() => {
    sendViewCollectionEvent({
      products: collection?.products,
    });
  }, [
    `${collection?.id}${!!collection?.products?.length}`,
    sendViewCollectionEvent,
  ]);

  return (
    <div
      key="collection-container"
      ref={collectionContainer}
      id={`${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
    >
      <FilterBar
        mobileFilterOpen={mobileFilterOpen}
        setMobileFilterOpen={setMobileFilterOpen}
        currentFilters={layoutParams.filters}
        setLayoutParams={setLayoutParams}
        items={products}
        sort={layoutParams.sort}
        browserHistory
      />
      <div className="lg:mx-auto lg:container collection__product-grid-container w-full px-5">
        <CollectionHeader
          sort={layoutParams.sort}
          setMobileFilterOpen={setMobileFilterOpen}
          setLayoutParams={setLayoutParams}
          currentFilters={layoutParams?.filters}
          itemCount={products.length}
          enableFilters={cms?.enableFilters}
          productCount={currentPageItems.length - tileCtas.currentPageCTAs.length}
          itemsPerPage={productsPerPage}
          enableLoadMore={cms?.enableLoadMore}
          currentPage={layoutParams?.page}
        />

        <ProductGrid
          productList={currentPageItems}
          promoTiles={promoTiles}
          showSubscriptionPrice={cms.showSubscriptionPrice}
          collection={collection}
        />
        {cms?.enableLoadMore ? (
          <LoadMore
            currentPage={layoutParams?.page}
            itemsPerPage={productsPerPage}
            currentPageItems={currentPageItems}
            items={products}
            setLayoutParams={setLayoutParams}
            scrollThreshold={cms?.scrollThreshold || 0}
          />
        ) : (
          <Pagination
            currentPage={layoutParams?.page}
            itemsPerPage={productsPerPage}
            items={products}
            setLayoutParams={setLayoutParams}
          />
        )}
      </div>
    </div>
  );
}

Section.displayName = 'Collection Grid';

Section.Schema = {
  category: 'Collection',
  label: Section.displayName,
  key: 'collection',
  fields: [
    {
      name: 'enableLoadMore',
      component: 'toggle',
      label: 'Enable Load More',
      defaultValue: true,
    },
    {
      name: 'scrollThreshold',
      component: 'number',
      label: 'Scroll Threshold',
      description:
        'What percentage of the page has the customer to scroll before loading more products?, recommended value 70/80',
      default: 80,
    }
  ],
};

export const Collection = Section;
