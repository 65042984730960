import { useMemo } from "react";
import { getSizedImageUrl } from "@/utils/Images";
import { getMetafieldMap } from "@/utils/Metafields";
import { useCartAddItem, useProductByHandle } from "@backpackjs/storefront";
import { useYotpoProductBottomline } from "@hooks";
import { YotpoStars } from "@/snippets/components/YotpoStars";
import ProductPrice from "./layout/ProductPrice";
import Link from "next/link";
import ProductBadge from "../products/ProductBadge";
import { Image } from "../components/Image";


export function HorizontalCard({productObject, imageClassNames, buttonClassNames, index}) {
  const { product } = useProductByHandle({
    handle: productObject?.handle?.replace('/products/', ''),
  });
  const { cartAddItem } = useCartAddItem();
  const addItemToCart = async (id) => {
    await cartAddItem(
      {
        merchandiseId: id,
        quantity: 1,
        attributes: [
          {
            key: '_sc',
            value: (typeof window.ShipScoutLineItemProperty !== 'undefined' ? window.ShipScoutLineItemProperty : 'null'),
          }
        ],
      },
      () => {
        window.dispatchEvent(new CustomEvent('openSideCart'));
      }
    );
  };

  const metafieldMap = useMemo(() => {
    return getMetafieldMap(product);
  }, [product?.id]);
  const imageUrl = useMemo(() => {
    return getSizedImageUrl(product?.featuredImage?.src, '200x200@2x');
  }, [product?.featuredImage?.src]);
  const productTitle = metafieldMap.custom_product_title || product?.title;
  const productIngredient = metafieldMap.custom_ingredient_title;
  const { bottomline } = useYotpoProductBottomline({
    legacyResourceId: product?.legacyResourceId,
  });

  if(!product) return null;

  return (
    <div className="w-full text-base mb-[34px] lg:mb-0 relative">
      <div className="flex h-full w-full flex-row items-center">
        <Link href={`/products/${product?.handle}`}>
          <a className="product-upsell__image-container relative block aspect-square bg-grey-9">
            <ProductBadge
              product={product}
              className="pointer-events-none !top-1 !right-2 z-10 !h-10 !w-10"
            />
            <div
              className={`pointer-events-none aspect-square w-[140px] ${imageClassNames}`}
            >
              {imageUrl && (
                <Image
                  src={imageUrl}
                  alt=""
                  sizes="(max-width=1023px) 50vw, 15vw"
                  fill
                  loading="lazy"
                />
              )}
            </div>
          </a>
        </Link>
        <div className="flex h-full w-full flex-col p-4 px-4 py-2 lg:flex-row lg:items-start lg:px-6 lg:py-0 lg:py-3">
          <Link href={`/products/${product?.handle}`}>
            <a className="product-upsell__info-container flex w-full flex-col items-start no-underline">
              {productIngredient && (
                <span className="text-xsmall pointer-events-none mb-[4px] text-left font-tiempos font-normal italic">
                  {productIngredient}
                </span>
              )}
              <span className="text-small pointer-events-none mb-[6px] text-left font-tiempos font-normal leading-[19px]">
                {productTitle}
              </span>
              <div
                className={`yotpo-reviews pointer-events-none mb-[6px] min-h-[17.5px]`}
              >
                {metafieldMap.catalog_bottomline ===
                '<div class="yotpo-display-wrapper" style=""> <div class="yotpo-clr"></div> </div>' ? (
                  <div className="yotpo-no-reviews text-sm text-grey-1 underline">
                    No reviews
                  </div>
                ) : (
                  bottomline && (
                    <div className="flex flex-wrap items-center gap-1.5">
                      {bottomline.total_reviews ? (
                        <>
                          <YotpoStars rating={bottomline.average_score} />
                        </>
                      ) : (
                        <p className="mb-0 text-[13px] leading-[17.5px] underline">
                          No reviews
                        </p>
                      )}
                    </div>
                  )
                )}
              </div>
              <ProductPrice
                variant={product?.variants[0]}
                className="product-upsell-prices pointer-events-none mb-0 block !text-xs !text-currentcolor xl-plus:hidden"
              />
            </a>
          </Link>
          <div className="flex flex-col items-end justify-center">
            <ProductPrice
              variant={product?.variants[0]}
              className="product-upsell-prices pointer-events-none mb-4 hidden !text-xs !text-currentcolor lg:block"
            />
            {product?.variants[0] && (
              <button
                type="button"
                className={`product-upsell__atc-button text-xsmall absolute bottom-[-35px] z-[1] mr-[-1rem] mt-auto  w-full !min-w-0 py-2 px-4 text-center font-gothamBold transition lg:relative lg:bottom-auto lg:mr-auto lg:mt-0 lg:w-[120px] ${buttonClassNames ? buttonClassNames : 'button--primary'}`}
                onClick={() => {
                  addItemToCart(product?.variants[0].id);
                }}
              >
                <span className="pointer-events-none mr-[4px]">add</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}