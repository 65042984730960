import { useCurrency } from "@backpackjs/storefront";
import { useProduct } from "@backpackjs/storefront";
import { useState } from "react";
import { useCartAddItem } from "@backpackjs/storefront";

export function VideoWise({ cms }) {
  const  currency  = useCurrency();
  const product = useProduct();
  const { cartAddItem } = useCartAddItem();
  const isBrowser = typeof window !== 'undefined';
  const [styleEl, setStyleEl] = useState(isBrowser ? document.getElementById('videowise-style-css') : null);
  const [vendorsEl, setVendorsEl] = useState(isBrowser ? document.getElementById('videowise-vendors-js') : null);
  const [clientEl, setClientEl] = useState(isBrowser ? document.getElementById('videowise-client-js') : null);
  const [addedProductToCart, setAddedProductToCart] = useState(false);

  const handleAddToCart = (event) => {
    setAddedProductToCart(true);
    const item = {
      merchandiseId: `gid://shopify/ProductVariant/${event.detail.variantId}`,
      quantity: event.detail.qty || 1,
      attributes: [
        {
          key: '_sc',
          value: (typeof window.ShipScoutLineItemProperty !== 'undefined' ? window.ShipScoutLineItemProperty : 'null'),
        }
      ]
    }

    if (event.detail.subscriptionId) {
      item.sellingPlanId = btoa(`gid://shopify/SellingPlan/${event.detail.subscriptionId}`)
    }

    cartAddItem(item)
  }

  const openCart = () => {
    if(addedProductToCart == true) {
      window.dispatchEvent(new CustomEvent('openSideCart'));
      setAddedProductToCart(false);
    }
  }

  useState(() => {
    if(isBrowser && !styleEl) {
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.as = 'style';
      style.href = 'https://assets.videowise.com/style.css.gz';
      style.id = 'videowise-style-css';
      document.head.appendChild(style);
      setStyleEl( document.getElementById('videowise-style-css'))
    }

    if(isBrowser && !vendorsEl) {
      const vendors = document.createElement('script');
      vendors.type = 'text/javascript';
      vendors.id = 'videowise-vendors-js';
      vendors.async = true;
      vendors.defer = true;
      vendors.src = 'https://assets.videowise.com/vendors.js.gz';
      document.head.appendChild(vendors);
      setVendorsEl( document.getElementById('videowise-vendor-js'))
    }

    if(isBrowser && !clientEl) {
      const client = document.createElement('script');
      client.type = 'text/javascript';
      client.id =('videowise-client-js');
      client.async = true;
      client.defer = true;
      client.src = 'https://assets.videowise.com/client.js.gz';
      document.head.appendChild(client);
      setClientEl( document.getElementById('videowise-client-js'))
    }
    if(isBrowser && !clientEl && !styleEl && !vendorsEl) {
      window.addEventListener('videowiseProductAddToCart', handleAddToCart);
      window.addEventListener('videowisePlayerClose', openCart);
    }

    return(() => {
      document.removeChild(styleEl);
      document.removeChild(vendorsEl);
      document.removeChild(clientEl);
      window.removeEventListener('videowiseProductAddToCart', handleAddToCart);
      window.removeEventListener('videowisePlayerClose', openCart);
    })
  }, [])

  return (
    <div
      id={`${VideoWise.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      data-comp={VideoWise.displayName}
      className="video-wise"
    >
      <script
        id="videoWiseSettiings"
        dangerouslySetInnerHTML={{
          __html: `var SKIP_CART=false;
          var FORCE_DOMAIN = true;
          var VIDEOWISE_FAST_INLINE_VIDEO_PLAYER = true;
          var Shopify = {
            shop: 'https://briogeo-hair-care.myshopify.com',
            currency: {
              active: "${currency || 'USD'}",
              rate: "1.0"
            }
          };

          var __st = {
            rid: ${product ? '"'+product.legacyResourceId+'"' : null},
            p: "${product ? 'product' : 'home'}"
          };

          var videowiseShadow = {
            mode: 'open',
            assets: [
              {
                type: 'style',
                src: "${ product ? '/components/videowise-product.css' : '/components/videowise.css'}",
              }
            ]
          };
        `,
        }}
      />

      <style>
        {`
        #${VideoWise.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
          margin-top: ${cms.mobileSpacing}px;
          margin-bottom: ${cms.mobileBottomSpacing}px;
        }
        @media(min-width: 1024px) {
          #${VideoWise.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.desktopSpacing}px;
            margin-bottom: ${cms.desktopBottomSpacing}px;
          }
        }
      `}
      </style>
      {cms?.codeSnippet && (
        <div
          className={`
            ${cms.flushEdges ? 'lg:px-0' : 'lg:container lg:px-5 lg:mx-auto'}
            ${cms.mobileFlushEdges ? 'px-0' : 'sm:container md:container px-5 mx-auto'}
          `}
          dangerouslySetInnerHTML={{ __html: cms.codeSnippet }}
        />
      )}
    </div>
  );
}

VideoWise.displayName = 'VideoWise';

VideoWise.Schema = {
  category: 'Modules',
  label: VideoWise.displayName,
  key: 'VideoWise',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      component: 'number',
      name: 'mobileBottomSpacing',
      label: 'Section Bottom Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopBottomSpacing',
      label: 'Section Bottom Margin - Desktop',
      defaultValue: 80,
    },
    {
      component: 'toggle',
      name: 'flushEdges',
      label: 'Flush to edges (desktop)',
      description: 'Allows the module to reach the edges of the container where it is located',
      defaultValue: false,
      toggleLabels: {
        true: 'yes',
        false: 'no',
      }
    },
    {
      component: 'toggle',
      name: 'mobileFlushEdges',
      label: 'Flush to edges (mobile)',
      description: 'Allows the module to reach the edges of the container where it is located',
      defaultValue: false,
      toggleLabels: {
        true: 'yes',
        false: 'no',
      }
    },
    {
      component: 'html',
      name: 'codeSnippet',
      label: 'VideoWise Code Snippet',
    }
  ]
}