import { useState, useEffect } from 'react';
import Link from 'next/link';
import { newLineToBr, scrollToSection } from '@/utils/Helper';

export function Accordion({ cms }) {
  const checkActiveItems = () => {
    let activeItem = -1;

    for (let i = 0; i < cms?.accordions?.length; i++) {
      if (cms?.accordions[i].active === true) {
        activeItem = i;
        break;
      }
    }

    return activeItem;
  };
  const [active, setActive] = useState(checkActiveItems);

  let hasAccordionTitle = false;
  cms?.accordions?.forEach((a) => {
    hasAccordionTitle ||= !!a.title;
  });

  const toggleAccordion = (e, index) => {
    e.preventDefault();
    if (active === index) {
      setActive(-1);
    } else {
      setActive(index);
    }
  };

  const jsonSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: cms?.accordions?.map((accordion) => {
      if (accordion?.subtitle && accordion?.description) {
        return {
          '@type': 'Question',
          name: accordion.subtitle,
          acceptedAnswer: {
            '@type': 'Answer',
            text: accordion.description,
          },
        };
      }
    }),
  };

  useEffect(() => {
    document.querySelectorAll('.accordion-item-panel').forEach((panel) => {
      panel.style.maxHeight = `${panel.scrollHeight}px`;
    });

    scrollToSection(
      `${Accordion.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`
    );
  });

  return (
    <div
      data-comp={Accordion.displayName}
      className="accordion"
      id={`${Accordion.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
    >
      <style>
        {`#${Accordion.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${Accordion.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div className="mx-auto w-max max-w-full px-0 md:px-5 lg:px-12 xl:max-w-[1440px]">
        {(cms?.title || cms?.description) && (
          <div className="px-4">
            {cms?.title && (
              <h3 className="text-header mb-3 text-center md:mx-auto md:mb-4 md:max-w-[430px]">
                {cms.title}
              </h3>
            )}
            {cms?.description && (
              <p
                className="mb-9 text-center font-light md:mx-auto md:mb-8 md:max-w-[430px] md:text-[18px] md:leading-[26px]"
                dangerouslySetInnerHTML={{
                  __html: newLineToBr(cms.description),
                }}
              />
            )}
          </div>
        )}
        {cms?.accordions?.length > 0 && (
          <div className="mx-auto max-w-[1120px]">
            {cms.accordions.map((accordion, accordionIdx) => (
              <div
                className={`accordion-item ${
                  active === accordionIdx ? 'active' : ''
                }`}
                key={`accordion-item-${accordionIdx}`}
                onClick={(e) => toggleAccordion(e, accordionIdx)}
              >
                {hasAccordionTitle && (
                  <div className="hidden w-1/5 shrink-0 border-r border-primary-1 md:block md:px-8 md:py-6">
                    {accordion?.strikethroughTitle ? (
                      <s>{accordion?.title}</s>
                    ) : (
                      accordion?.title
                    )}
                  </div>
                )}
                <div className="accordion-item-body">
                  <div
                    className={`${
                      accordion?.image ? 'w-full md:w-[65%]' : 'w-full'
                    }`}
                  >
                    {accordion?.title ? (
                      <div className="accordion-item-heading">
                        <div className="md:hidden">
                          {accordion?.strikethroughTitle ? (
                            <s>{accordion?.title}</s>
                          ) : (
                            accordion?.title
                          )}
                        </div>
                        <p className="mb-0 hidden font-gothamBold leading-[26px] md:block ">
                          {accordion?.subtitle}
                        </p>
                      </div>
                    ) : (
                      <div className="accordion-item-heading">
                        <p className="mb-0 font-gothamBold leading-[26px]">
                          {accordion?.subtitle}
                        </p>
                      </div>
                    )}
                    <div
                      className={`accordion-item-panel ${
                        active === accordionIdx ? '' : '!max-h-0'
                      }`}
                    >
                      <div className="pl-4 pb-6 pr-12 lg:px-10">
                        {accordion?.title && (
                          <p className="mb-3 font-gothamBold leading-[26px] md:hidden">
                            {accordion?.subtitle}
                          </p>
                        )}
                        <p
                          className="mb-0 text-[14px] font-light leading-[22px] md:text-[16px] md:leading-[24px]"
                          dangerouslySetInnerHTML={{
                            __html: newLineToBr(accordion?.description),
                          }}
                        />
                      </div>
                      {accordion?.image && (
                        <div className="accordion-item-image-wrapper">
                          <img
                            className="top-0 left-0 h-full w-full object-cover md:absolute"
                            src={accordion.image.src}
                            alt={accordion?.imageAlt || ''}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <button className="accordion-trigger" />
              </div>
            ))}
          </div>
        )}
        {cms?.cta_link?.text && cms?.cta_link?.url && (
          <div className="flex justify-center pt-[38px]">
            <Link href={cms.cta_link?.url}>
              <a className="button button--outline cursor-pointer text-[14px] leading-[22px] no-underline">
                {cms.cta_link?.text}
              </a>
            </Link>
          </div>
        )}
      </div>
      {cms?.enableFaq && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonSchema) }}
        />
      )}
    </div>
  );
}

Accordion.displayName = 'Accordion';

Accordion.Schema = {
  category: 'Product',
  label: Accordion.displayName,
  key: 'Accordion',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'description',
      label: 'Description',
      component: 'textarea',
    },
    {
      name: 'enableFaq',
      label: 'Enable FAQ',
      component: 'toggle',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: false,
      description:
        'Enables FAQ Schema for crawlers to recognize the frenquently asked questions, should be used on FAQ template',
    },
    {
      name: 'cta_link',
      label: 'CTA Link',
      component: 'link',
    },
    {
      name: 'accordions',
      label: 'Accordions',
      component: 'group-list',
      fields: [
        {
          component: 'toggle',
          name: 'active',
          label: 'Active by default',
          description:
            'Shows this drawer item open by default, only one can be open at any time',
          defaultValue: false,
        },
        {
          name: 'strikethroughTitle',
          label: 'Strikethrough Title?',
          component: 'toggle',
          toggleLabels: {
            true: 'Yes',
            false: 'No',
          },
          defaultValue: false,
        },
        {
          name: 'title',
          label: 'Title',
          component: 'text',
        },
        {
          name: 'subtitle',
          label: 'Subtitle',
          component: 'text',
        },
        {
          name: 'description',
          label: 'Description',
          component: 'textarea',
        },
        {
          name: 'image',
          label: 'Image',
          component: 'image',
          description: 'Recommended Image Size: 750px x 620px',
        },
        {
          name: 'imageAlt',
          label: 'Image alt text',
          component: 'text'
        },
      ],
    },
  ],
};
