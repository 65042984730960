import { useRef } from 'react';
import { useQuizContext } from './QuizContext';
import { Image } from '@/snippets/components/Image';
import { handleize } from '@/utils/Helper';

export function QuizOption({ index, optionData, isMultipleChoice, showOptionsImages, isFilter, currentAnswers}) {
  const context = useQuizContext();
  const { quizData, updateQuizData } = context;
  const checkbox = useRef();
  let defaultChecked = false;

  const onOptionChange = (evt) => {
    if(evt.target.type !== 'checkbox') return;
    const allInputs = evt.target.closest('.quiz-steps__options-container').querySelectorAll('input:checked');
    if(['', 'everything'].includes(evt.target.value.toLowerCase())) {
      if(allInputs?.length) {
        const otherInput = [...allInputs].filter(input => input !== evt.target);
        otherInput.forEach(input => {
          input.checked = false;
        });
      }
    } else {
      const otherInput = [...allInputs].filter(input => ['', 'everything'].includes(input.value.toLowerCase()));
      if(allInputs.length) {
        otherInput.forEach(input => {
          input.checked = false;
        })
      }
    }
  }

  const removeError = () => {
    updateQuizData({
      hasError: false,
    });
  }

  if(Array.isArray(currentAnswers?.answer)) {
    defaultChecked = currentAnswers?.answer?.includes(String(index)) || currentAnswers?.answer?.includes(optionData.value);
  } else {
    defaultChecked = currentAnswers?.answer == index;
  }

  return (
    <div key={`option-${index}`} className={`quiz__option h-auto w-full`}>
      <input
        ref={checkbox}
        type={isMultipleChoice ? 'checkbox' : 'radio'}
        name={`question-${quizData.currentQuestion}`}
        onChange={(evt) => {
          onOptionChange(evt);
          removeError();
        }}
        value={isFilter ? optionData.value : index}
        id={handleize(optionData.label)}
        className="visually-hidden peer !absolute"
        defaultChecked={defaultChecked}
      />
      <label
        htmlFor={handleize(optionData.label)}
        className={`flex flex-col items-center justify-center h-full cursor-pointer overflow-hidden border-2 border-black text-center peer-checked:border-quinary-1 peer-checked:bg-quinary-1 peer-checked:text-white peer-focus:outline peer-focus:outline-1 lg:hover:border-quinary-1 lg:hover:bg-quinary-1 lg:hover:text-white
         ${
           !showOptionsImages
             ? 'rounded-full peer-checked:border-quinary-1'
             : 'rounded-[20px]'
         }
        `}
      >
        {showOptionsImages && (
          <div
            className={`quiz__option-image border-b-2 w-full ${
              checkbox.current?.checked ? 'border-quinary-1' : 'border-black'
            }`}
          >
            <Image
              src={optionData.image?.src}
              alt={optionData.image?.alt}
              width={140}
              height={80}
              sizes="140px, 250px, 350px"
              className="aspect-[140/80] h-full w-full object-cover"
            />
          </div>
        )}
        <span className="block py-2.5 px-5 font-gothamBold text-sm lg:text-base">
          {optionData.label}
        </span>
      </label>
    </div>
  );
}
