import Script from 'next/script';

import {
  useDataLayerAccount,
  useDataLayerCart,
  useDataLayerCollection,
  useDataLayerCustomer,
  useDataLayerInit,
  useDataLayerProduct,
  useDataLayerSearch,
  useDataLayerSubscribe,
} from './hooks';

const DEBUG = false; // local debugging (logs whether passed or failed with elevar)

// DEBUG via Elevar's Data Layer Listener, add to the console:
// Turn on: window.ElevarGtmSuiteListener.utils.debug(true)
// Turn off: window.ElevarGtmSuiteListener.utils.debug(false)

// ENVS to set:
// * NEXT_PUBLIC_SITE_TITLE // provides backup brand name

export function DataLayerWithElevar() {
  const { generateUserProperties, userProperties } = useDataLayerInit({
    DEBUG,
  });

  const { userDataEvent, userDataEventTriggered } = useDataLayerCustomer({
    DEBUG,
    userProperties,
  });

  useDataLayerAccount({
    DEBUG,
    generateUserProperties,
    userDataEvent,
    userDataEventTriggered,
  });

  useDataLayerCart({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerProduct({
    DEBUG,
    userDataEvent,
    userProperties,
  });

  useDataLayerCollection({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSearch({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSubscribe({
    DEBUG,
    userDataEventTriggered,
  });

  return (
    <Script
      type="module"
      id="elevar-external-tracking-config"
      dangerouslySetInnerHTML={{
        __html: `
        try {
          const settings = {};
          const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/277a503835c54da52a2ee30c1cebe66252f0f692/config.js")).default;
          const scriptUrl = settings.proxyPath
            ? settings.proxyPath + config.script_src_custom_pages_proxied
            : config.script_src_custom_pages;

          if (scriptUrl) {
            const { handler } = await import(scriptUrl);
            await handler(config, settings);
          }
        } catch (error) {
          console.error("Elevar Error:", error);
        }
      `,
      }}
    ></Script>
  );
}

DataLayerWithElevar.displayName = 'DataLayerWithElevar';
