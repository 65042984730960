import { useEffect, useRef, useState } from "react";
import { Info } from "../icons/Info";
import Styles from './InfoPopup.module.css';
import { Close } from "../icons/Close";

export function InfoPopup({settings}) {
  const closeButton = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const textColor = settings.text_color == 'White' ? 'text-white' : 'text-black';

  useEffect(() => {
    if(isOpen == true) {
      closeButton.current.focus();
    }
  }, [isOpen])

  return (
    <div className="relative">
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="info-popup__trigger mt-4 flex rounded-full border border-black px-3.5 py-1 font-gothamBold text-sm"
        style={{ backgroundColor: settings?.label_background }}
      >
        <span className="mr-2 rounded-full bg-white leading-none tracking-[0]">
          <Info className="-mr-0.5 -mb-0.5 block w-6" />
        </span>
        {settings?.tooltip_label}
      </button>
      {isOpen && (
        <div
          className="info-popup__content absolute top-full z-10 mt-4 w-[295px] max-w-full border border-grey-4 p-4 shadow-md shadow-grey-4"
          style={{
            backgroundColor: settings?.bubble_background,
          }}
        >
          <span
            className="bt-1 br-1 absolute -top-2 z-[1] block h-4 w-4 rotate-45 border-t border-l border-grey-4"
            style={{ backgroundColor: settings?.bubble_background }}
          />
          <button
            type="button"
            onClick={() => {
              setIsOpen(false);
            }}
            ref={closeButton}
            className={`${textColor} absolute right-4 top-4`}
          >
            <div className="sr-only">Close tooltip</div>
            <Close className="h-3 w-3 text-currentcolor" />
          </button>
          <div
            className={`info-popup__content ${textColor} ${Styles['info-popup']} pr-5 text-sm`}
            dangerouslySetInnerHTML={{
              __html: settings.body_content
                .replaceAll(
                  '<img',
                  '<img class="inline-block mr-2 h-[16px] aspect-square" '
                )
                .replaceAll('<p', '<p class="mb-0" '),
            }}
          />
        </div>
      )}
    </div>
  );
}