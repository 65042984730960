import { useCallback, useMemo, useState} from 'react';
import { useCartAddItem, useCountry, useLocalizedVariant, useProductByHandle } from '@backpackjs/storefront';
import { useInView } from 'react-intersection-observer';
import Link from 'next/link';
import { useDataLayerActions, useYotpoProductBottomline } from '@hooks';
import ProductPrice from './ProductPrice';
import { getMetafieldMap, getMetafieldColor } from '@/utils/Metafields';
import { getSizedImageUrl } from '@/utils/Images';
import ProductBadge from '@/snippets/products/ProductBadge';
import { YotpoStars } from '@/snippets/components/YotpoStars';
import { Image } from '@/snippets/components/Image';
import BuyWithPrimeBadge from '@/snippets/products/BuyWithPrimeBadge';

function ProductCard({
  product: passedProduct,
  handle,
  index,
  showReviews = true,
  quickViewButton = 'top',
  showBWPBadge,
  isSearch,
  showSubscriptionPrice = false,
  showQuickBuyButton = false,
}) {
  const { ref, inView } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  });
  const { country } = useCountry();
  const countryCode = country?.isoCode || 'US';
  const isUs = countryCode === 'US';
  const { sendClickProductItemEvent } = useDataLayerActions();
  const { product: fetchedProduct } = useProductByHandle({ handle, fetchOnMount: inView });
  const product = fetchedProduct || (passedProduct?.loaded ? passedProduct : null);
  const [adding, setAdding] = useState(false);
  const firstSellingPlan = showSubscriptionPrice
    ? product?.variants?.[0]?.sellingPlanAllocations?.[0]
    : null;

  const taggedBWP = product?.tags?.indexOf('BWP') > -1;
  const { bottomline } = useYotpoProductBottomline({
    legacyResourceId: product?.legacyResourceId,
  });
  const { localized } = useLocalizedVariant({ variant: product?.variants?.[0] });
  let ShowBuyWithPrime = false;
  if (showBWPBadge && taggedBWP) {
    ShowBuyWithPrime = true;
  }

  const metafieldMap = useMemo(() => {
    return getMetafieldMap(product);
  }, [product, product?.id]);

  const primaryImgSrc = useMemo(() => {
    if (!product?.images[0]?.src) return null;
    return getSizedImageUrl(product.images[0].src, '750x');
  }, [product?.images[0]?.src]);

  const hoverImgSrc = useMemo(() => {
    if (!product?.images[1]?.src) return null;
    return getSizedImageUrl(product.images[1].src, '750x');
  }, [product?.images[1]?.src]);

  const toggleQuickViewModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    document.dispatchEvent(
      new CustomEvent('quickViewModal:open', {
        detail: {
          product: {
            ...product,
            enableSubscription: isUs ? true : false,
          },
        },
      })
    );
  };

  const collection = metafieldMap.custom_collection_title;

  const handleClick = useCallback(() => {
    sendClickProductItemEvent({
      isSearchResult: isSearch,
      listIndex: index,
      localized,
      product,
      selectedVariant: product?.variants?.[0],
    });
    if (typeof onClick === 'function') onClick();
  }, [index, localized, product?.id]);


  const { cartAddItem } = useCartAddItem();
  const addItemToCart = async (id, openMiniCart = true) => {
    await cartAddItem(
      {
        merchandiseId: id,
        quantity: 1,
        attributes: [
          {
            key: '_sc',
            value: (typeof window.ShipScoutLineItemProperty !== 'undefined' ? window.ShipScoutLineItemProperty : 'null'),
          }
        ]
      },
      () => {
        if(openMiniCart) {
          window.dispatchEvent(new CustomEvent('openSideCart'));
        }
        setAdding(false);
      }
    );
  };

  return (
    <div
      key={product?.legacyResourceId}
      className="product-card flex h-full flex-col"
      id={product?.legacyResourceId}
      ref={ref}
    >
      <Link href={`/products/${product?.handle}`}>
        <a
          className="flex h-full flex-col no-underline pt-[45px] lg:pt-0 bg-[#f8f8f8] lg:bg-transparent"
          aria-label={`Go to product ${product?.title}`}
          onClick={handleClick}
        >
          <div className="product-card__image group relative block aspect-square shrink-0">
            <div className="pdp-custom-badges flex flex-col items-end absolute top-[-40px] lg:top-0 right-[2px] md:right-0 mt-2 md:mt-6">
              {metafieldMap.custom_badge_label_1 && (
                <div className="pdp-custom-badge-container-2 z-[1]">
                  <span
                    className="pdp-custom-badge-1 rounded-tl-md rounded-bl-md tracking-wide font-gothamBold text-[12px] pt-[8px] pr-[10px] pb-[8px] pl-[12px]"
                    style={{ backgroundColor: metafieldMap.custom_badge_label_bg_1, color: metafieldMap.custom_badge_label_color_1}}>
                    {metafieldMap.custom_badge_label_1}
                  </span>
                </div>
              )}
              {metafieldMap.custom_badge_label_2 && (
                <div className="pdp-custom-badge-container-2 mt-2 md:mt-4 z-[1]">
                  <span
                    className="pdp-custom-badge-2 rounded-tl-md rounded-bl-md tracking-wide font-gothamBold text-[12px] pt-[8px] pr-[10px] pb-[8px] pl-[12px]"
                    style={{ backgroundColor: metafieldMap.custom_badge_label_bg_2, color: metafieldMap.custom_badge_label_color_2}}>
                    {metafieldMap.custom_badge_label_2}
                  </span>
                </div>
              )}
            </div>

            <ProductBadge
              product={product}
              className={`md:!h-13 md:!w-13 z-[8] !h-11 !w-11 ${
                ShowBuyWithPrime ? '!bottom-[50px] md:!bottom-[75px]' : '!bottom-[-0.25rem] lg:!bottom-3'
              }`}
              forceSaveBadge={showSubscriptionPrice}
            />
            {ShowBuyWithPrime === true && (
              <BuyWithPrimeBadge className="z-[8] md:w-13 md:h-13 absolute bottom-[-0.25rem] lg:bottom-3 left-3 h-11 w-11" />
            )}
            {primaryImgSrc && (
              <div className="top-0 left-0 absolute aspect-square h-full w-full object-cover transition-opacity duration-300 lg:group-hover:opacity-0">
                <Image
                  src={primaryImgSrc}
                  alt=""
                  loading="lazy"
                  fill
                  sizes="(max-width: 768px) 50vw,
                         (max-width: 1024) 35vw,
                         27vw"
                  data-primary-image
                />
              </div>
            )}
            {hoverImgSrc && (
              <div className="l-0 t-0 absolute aspect-square h-full w-full object-cover opacity-0 transition-opacity duration-300 lg:group-hover:opacity-100">
                <Image
                  src={hoverImgSrc}
                  alt=""
                  fill
                  sizes="(max-width: 768px) 50vw,
                         (max-width: 1024) 35vw,
                         27vw"
                  loading="lazy"
                  data-hover-image
                />
              </div>
            )}
            {quickViewButton === 'top' && (
              <button
                type="button"
                className="z-[8] button button--outline pointer-events-none absolute bottom-5 left-0 right-0 mx-auto hidden w-[225px] max-w-[80%] opacity-0 transition-opacity duration-300 group-hover:pointer-events-auto group-hover:opacity-100 lg+:block"
                onClick={toggleQuickViewModal}
              >
                {showSubscriptionPrice === true
                  ? 'subscribe & save'
                  : 'quick view'}
              </button>
            )}
          </div>
          <div className="product-card__body mt-3.5 pt-3 lg:pt-0 flex h-full flex-col bg-white">
            {collection && (
              <span
                className={`mb-0 font-gothamBold text-[10px] ${getMetafieldColor(metafieldMap)}`}
              >
                {collection}
              </span>
            )}
            {metafieldMap.custom_ingredient_title && (
              <span className="mb-0.5 block font-tiempos text-sm italic">
                {metafieldMap.custom_ingredient_title}
              </span>
            )}
            <h3 className="mb-2 font-gothamBold text-sm text-grey-1">
              {metafieldMap.custom_product_title || product?.title}
            </h3>
            {showReviews && (
              <div
                className={`yotpo-reviews pointer-events-none mb-2.5 min-h-[17.5px] ${
                  showSubscriptionPrice === true ? 'hidden lg:block' : ''
                }`}
              >
                {bottomline && bottomline.total_reviews > 0 ? (
                  <div className="flex flex-wrap items-center gap-1.5">
                    {bottomline.total_reviews ? (
                      <>
                        <YotpoStars rating={bottomline.average_score} />
                        <p className="mb-0 flex-1 text-[13px] leading-[17.5px] underline max-md:hidden">
                          {bottomline.total_reviews} Review
                          {bottomline.total_reviews === 1 ? '' : 's'}
                        </p>
                      </>
                    ) : (
                      <p className="mb-0 text-[13px] leading-[17.5px] underline">
                        No reviews
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="yotpo-no-reviews text-sm text-grey-1 underline">
                    No reviews
                  </div>
                )}
              </div>
            )}
            <ProductPrice
              variant={product?.variants[0]}
              planPrice={firstSellingPlan?.priceAdjustments[0].price?.amount}
              selectedPlan={firstSellingPlan}
              className="mb-5"
            />
            {showSubscriptionPrice === true &&
              metafieldMap.annual_savings_message !== undefined && (
                <p className="product-card__savings-message mb-5 -mt-3 block font-gothamBold text-sm font-bold text-primary-2">
                  {metafieldMap.annual_savings_message}
                </p>
              )}
            {quickViewButton === 'bottom' && (
              <button
                type="button"
                className="button button--outline bottom-5 mx-auto mt-auto hidden w-full lg+:block"
                onClick={toggleQuickViewModal}
              >
                {showSubscriptionPrice === true
                  ? 'subscribe + save'
                  : 'quick view'}
              </button>
            )}
            {showSubscriptionPrice === true && (
              <Link href={`/products/${product?.handle}?subscription`}>
                <a className="button button--secondary mt-auto !min-w-0 !text-xs no-underline lg:hidden">
                  subscribe & save
                </a>
              </Link>
            )}
          </div>
        </a>
      </Link>
      {showQuickBuyButton === true && (
        <button
          type="button"
          className="product-mobile-plp-add-to-cart button button--checkout mt-auto h-[44px] !min-w-0 !text-xs !leading-none no-underline"
          onClick={(e) => {
            addItemToCart(product?.variants[0].id, false);
            setAdding(true);
          }}
        >
          {adding ? 'adding...' : 'add to cart'}
        </button>
      )}
    </div>
  );
}



export default ProductCard;
